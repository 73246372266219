/* global window */

import { get as serverGet, del, post, put } from '../server';
import { getUrlNextPage } from 'Reducers/componentHelpers';
import { getUserFavouritesIds } from './user';
import * as urlHelper from 'Helpers/url';
import { urls as autocompleteUrls } from 'Reducers/autocomplete';
import { urls as itemsUrls } from 'Reducers/items';
import { customActions as customUsersActions } from 'Reducers/users';
import { add as addRecentSearch } from 'Helpers/recentSearches';
import Auth from '../Auth';
import { recieveFavourites } from 'Actions/user';
import {
    getItemsUrl,
    itemsUserSelector,
    itemsUserFetching,
    callHasBeenMadeForItemsUser,
    getItemsParamsSelector
} from 'Selectors/items';
import { getLoggedUser } from 'Selectors/user';
import { getSiteCode, getSelectedLangCode } from 'Selectors/config';
import { getSessionLong } from 'Helpers/userSession';
import CookieManager from 'Helpers/cookies';
import { PLATFORM } from 'Constants/device.APP_TARGET';
import { updateBundles } from 'Actions/bundles';
import { getURLParams } from 'Selectors/url';
import { APIVERSION, CARS_CATEGORY_PAGE_SIZE, ITEMS_SEARCH_SOURCE, ITEMS_FEED_SOURCE } from 'Constants/items';
import { getType } from 'Reducers/helpers/typeModifier';
import { shouldRetry } from 'Helpers/ssrRetry';
import { NESTED_FILTER_PARAM } from 'Constants/filters';

const updateCollectionUrl = url => ({
    type: 'UPDATE_COLLECTION_URL',
    url
});

const resetItemsList = () => ({ type: 'RESET_ITEMS_LIST' });
const mergeItemsList = url => ({ type: 'MERGE_ITEMS_LIST', url });
const setItemsList = url => ({ type: 'SET_ITEMS_LIST', url });
const resetCollection = () => ({ type: 'RESET_ITEMS_COLLECTION' });
const shouldFetchByUrl = (state, url) => {
    const type = getType('ITEMS', url);
    const isFetching = state.items.isFetching[type];
    const hasData = state.items.collections[type];
    const hasFailed = state.items.isError[type];

    return !isFetching && !hasData && !hasFailed;
};

const sortRelevanceParams = params => {
    return Object.keys(params)
        .sort()
        .reduce((prev, curr) => ({ ...prev, [curr]: params[curr] }), {});
};

const trackSearch = (path, queryParams) => (dispatch, getState, { post }) => {
    const state = getState();
    const sitecode = getSiteCode(state);
    const user = getLoggedUser(state);
    const sessionCookie = CookieManager.readCookie('onap');
    const sessionLongByCookie = sessionCookie ? getSessionLong(sessionCookie) : '';
    const date = new Date();
    const CLIENT_ID_HEADER = `web-${PLATFORM}`;

    if (!sessionLongByCookie) {
        return Promise.resolve();
    }

    const searchQueryParams = {
        ...queryParams,
        user: user && user.id || ''
    };

    if (searchQueryParams[NESTED_FILTER_PARAM] && typeof searchQueryParams[NESTED_FILTER_PARAM] === 'object') {
        // to convert object into escaped string i.e. { "key1": "value1"} to "{ \"key1\": \"value1\"}"
        searchQueryParams[NESTED_FILTER_PARAM] = JSON.stringify(searchQueryParams[NESTED_FILTER_PARAM]).replace(/"/g, '\\"');
    }

    return dispatch(post(itemsUrls.trackSearch, 'BXP_TRACK_SEARCH', {
        sitecode,
        platform: CLIENT_ID_HEADER,
        sessionId: sessionLongByCookie,
        userId: user && user.id || '',
        date: date.toISOString(),
        search: {
            path,
            query: searchQueryParams
        }
    }, {
        'x-panamera-client-id': CLIENT_ID_HEADER
    }));
};

/**
 * Makes the API call for fetching Items based on Source and Params
 * If the url has already been used to fetch before, the response will be used from the State
 * @param {*} itemsSource
 * @param {*} params
 * @param {string} apiVersion
 * @param {boolean} refetch If true, it will bypass the collection's cache and fetch items from the API again.
 */
const getItemsData = (itemsSource, params = {}, apiVersion = APIVERSION.V1, retrySSRFailed, refetch = false, config = {}) => (dispatch, getState, { get }) => {
    const state = getState();
    const itemsUrl = getItemsUrl(itemsSource, apiVersion);

    params.page = (!params?.page || params.page === 1) ? null : params.page - 1;

    const sortedRelevanceParams = sortRelevanceParams(params);
    const stringParams = getURLParams(state, sortedRelevanceParams);
    const url = stringParams ? `${itemsUrl}?${stringParams}` : itemsUrl;
    const type = getType('ITEMS', url);

    const isFetching = state.items.isFetching[type];
    const hasData = state.items.collections[type];
    const hasFailed = state.items.isError[type];
    const errorMetadata = state.items.errorsMetadata && state.items.errorsMetadata[type];
    const retry = retrySSRFailed && shouldRetry(errorMetadata);
    const { lastCollectionUrl } = state.items;
    const doesHaveSimilarAds = Object.keys(state?.items?.similarAdsData || {})?.length > 0;

    // if its a fresh new url being fetched
    // make a fresh new request
    if (!(isFetching || hasData || (hasFailed && !retry)) || refetch) {
        dispatch(updateCollectionUrl(url));

        // this is to clear all elements and paginated calls (load more) , if not done paginated calls data would be stale
        if (refetch) {
            dispatch(resetCollection());
        }
        dispatch(resetItemsList());

        dispatch(trackSearch(itemsUrl, sortedRelevanceParams));
        dispatch(updateBundles(true));
        return dispatch(get(url, 'ITEMS', {}, {}, { config })).then(res => {
            if (res && res.ok) {
                return Promise.resolve(res.data);
            }
            return Promise.reject(res);
        });
    }

    // if the last items collection which was fetched isnt same as current url
    if (lastCollectionUrl !== url) {
        // extract the params from the lastCollectionUrl
        // and compare it with the current url being fetched
        const stripParams = (lastCollectionUrl.indexOf('?') === -1)
            ? {}
            : urlHelper.queryStringToParams(lastCollectionUrl.slice(lastCollectionUrl.indexOf('?') + 1));
        const { cursor, suggestedPage, ...res } = stripParams; // eslint-disable-line

        if (itemsSource === ITEMS_FEED_SOURCE || (itemsSource === ITEMS_SEARCH_SOURCE && doesHaveSimilarAds) || params?.query) {
            // 1. Ensures the Load More CTA functions correctly.
            // 2. When navigating from page 2 to page 1, displays page 1's content.
            delete res.page;
        }

        const lastCollectionStringParams = urlHelper.paramsToQueryString(sortRelevanceParams(res));
        const areParamsEquals = urlHelper.compareEncodedParams(stringParams, lastCollectionStringParams);

        const searchTitle = sortedRelevanceParams.query;
        const itemCount = hasData?.length;

        if (searchTitle && itemCount) {
            addRecentSearch({ title: searchTitle });
        }

        // if the params are not equal
        // we will clear the collection stored in our state
        if (!areParamsEquals) {
            const newUrlWithParams = (stringParams.length > 0) ? `${itemsUrl}?${stringParams}` : itemsUrl;

            dispatch(resetItemsList());
            dispatch(updateCollectionUrl(newUrlWithParams));
            dispatch(setItemsList(newUrlWithParams));
        }
    }
    return Promise.resolve();
};

const getRandomRelevanceUser = userIdentifier => {
    let userCookie = userIdentifier || CookieManager?.readCookie('relevanceUser');

    if (!userCookie) {
        userCookie = Math.random().toString().replace('.', '');
        CookieManager?.createCookie('relevanceUser', userCookie);
    }
    return userCookie;
};

const getItems = ({
    itemsSource,
    facetLimit,
    cookies,
    location,
    apiVersion = APIVERSION.V1,
    givenCategoryId,
    retrySSRFailed = false,
    refetch = false,
    fetchFavourite = true,
    config = {},
    sessionFeatures
}) => (dispatch, getState) => {
    const state = getState();
    const userIdentifier = cookies && cookies.relevanceUser;

    const userCookie = getRandomRelevanceUser(userIdentifier);

    const user = { user: userCookie };
    const { location: locationUrl, category, search } = urlHelper.buildObjectFromURL(location.pathname);
    const params = {
        geoID: locationUrl && locationUrl.id,
        categoryID: (category && category.id) || givenCategoryId,
        text: search,
        isSearchCall: location.query && location.query.isSearchCall,
        apiVersion,
        relaxedFilters: true,
        pttEnabled: true
    };

    if (apiVersion === APIVERSION.V4
        && ITEMS_SEARCH_SOURCE === itemsSource) {
        params.platform = `web-${PLATFORM}`;
    }
    // Note: As per OLXIN-1117, on the ads listing page, the size should now be 40 ads per page
    // across categories in both desktop and mobile view.
    if (ITEMS_SEARCH_SOURCE === itemsSource) {
        params.size = CARS_CATEGORY_PAGE_SIZE;
    }
    const itemsParams = {
        ...user,
        ...getItemsParamsSelector(itemsSource)(state, { location, params, facetLimit })
    };

    return Promise.all([
        fetchFavourite && dispatch(getUserFavouritesIds()),
        dispatch(getItemsData(itemsSource, itemsParams, apiVersion, retrySSRFailed, refetch, config, sessionFeatures || []))
    ].filter(Boolean));
};

const loadNextPage = (itemsSource, apiVersion = APIVERSION.V1) => (dispatch, getState) => {
    const state = getState();
    const { lastCollectionUrl, collectionMetadata } = state.items;
    let type = getType('ITEMS', lastCollectionUrl);
    const { nextPageUrl, nextSuggestedPageUrl } = collectionMetadata[type] || {};

    const nextPage = nextSuggestedPageUrl || nextPageUrl;
    const nextPageParams = nextPage ? urlHelper.paramsToQueryString(
        sortRelevanceParams(urlHelper.queryStringToParams(nextPage))
    ) : undefined;

    const itemsUrl = getItemsUrl(itemsSource, apiVersion);
    const nextUrl = nextPageParams ? `${itemsUrl}?${nextPageParams}` : itemsUrl;

    type = getType('ITEMS', nextUrl);
    const isFetching = state.items.isFetching[type];
    const hasFailed = state.items.isError[type];
    const hasData = state.items.collections[type];

    if (!isFetching && !hasData && !hasFailed) {
        dispatch(updateCollectionUrl(nextUrl));
        return dispatch(serverGet(nextUrl, 'ITEMS')).then(res => {
            if (res && res.ok) {
                return Promise.resolve(res.data);
            }
            return Promise.reject(res);
        });
    }

    dispatch(updateCollectionUrl(nextUrl));
    dispatch(mergeItemsList(nextUrl));
    return Promise.resolve();
};

const getItemsUser = (userId, params, force = false) => (dispatch, getState, { get }) => {
    if (!userId) {
        return Promise.reject();
    }
    const state = getState();
    const itemsUser = itemsUserSelector(state, userId, params);

    if (!force && (itemsUser.length || callHasBeenMadeForItemsUser(state, userId, params) || itemsUserFetching(state, userId, params))) {
        return Promise.resolve();
    }

    return dispatch(get(itemsUrls.getUserAds(userId), 'ITEMS', params));
};

const getDealerSearchedItems = (userId, params, loadMoreUrl) => (dispatch, getState, { get }) => {
    const state = getState();
    const lang = getSelectedLangCode(state);

    if (lang) {
        params.lang = lang;
    }
    if (loadMoreUrl.length > 0) {
        const searchEndpoint = loadMoreUrl.replace('http://api.olx.in', '/api');

        return dispatch(get(searchEndpoint, 'ITEMS'));
    }
    return dispatch(get(itemsUrls.dealerSearchItems(userId), 'ITEMS', params));
};

const loadNextPageItemsUser = (userId, params) => (dispatch, getState) => {
    const state = getState();
    const lang = getSelectedLangCode(state);

    if (lang) {
        params.lang = lang;
    }

    dispatch(
        serverGet(getUrlNextPage(state.items, itemsUrls.getUserAds(userId), 'ITEMS', params), 'ITEMS')
    );
};

const getFavoriteItemsUser = () => (dispatch, getState, { get }) => {
    const user = Auth.getUser();

    if (!user) {
        return Promise.resolve();
    }

    const state = getState();
    const { id } = user;
    const favUrl = itemsUrls.getUserFavAds(id);
    const type = getType('ITEMS', favUrl);
    const isFetching = state.items.isFetching[type];
    const hasFailed = state.items.isError[type];
    const hasData = state.items.collections[type];
    const sitecode = getSiteCode(state);
    const headers = { 'x-panamera-sitecode': sitecode };

    if (!isFetching && !hasData && !hasFailed) {
        return dispatch(
            get(itemsUrls.getUserFavAds(id), 'ITEMS', {}, headers))
            .then(response => dispatch(recieveFavourites(response)
            )
            );
    }

    return Promise.resolve();
};

const loadNextPageFavoriteItemsUser = (userId, params = {}) => (dispatch, getState, { get }) => {
    const state = getState();
    const userLogged = Auth.getUser();
    const lang = getSelectedLangCode(state);
    const sitecode = getSiteCode(state);
    const headers = { 'x-panamera-sitecode': sitecode };

    if (lang) {
        params.lang = lang;
    }

    dispatch(get(getUrlNextPage(state.items, itemsUrls.getUserFavAds(userId), 'ITEMS', params), 'ITEMS', params, headers))
        .then(response => userLogged && userLogged.id === userId && dispatch(recieveFavourites(response)));
};

const getSearchSuggestions = params => dispatch => {
    dispatch(
        serverGet(autocompleteUrls.getSearchSuggestions, 'SUGGESTIONS', params))
        .then(() => Promise.resolve())
        .catch(error => {
            if (window && window.newrelic && window.newrelic.noticeError) {
                window.newrelic.noticeError(new Error(`[Error] [SearchSuggestions] ${error}`));
            }
            return Promise.reject();
        });
};

const getRecommendations = (userId, itemId, categoryId, item = {}) => (dispatch, getState, { get }) => {
    const state = getState();
    const userIdentifier = userId || '';
    const lat = (item && item.locations && item.locations[0] && item.locations[0].lat) || null;
    const lon = (item && item.locations && item.locations[0] && item.locations[0].lon) || null;
    const price = (item && item.price && item.price.value && item.price.value.raw) || null;
    let make = item && item.parameters && item.parameters.find(item => item.key === 'make');

    make = (make && make.value) || null;
    let model = item && item.parameters && item.parameters.find(item => item.key === 'model');

    model = (model && model.value) || null;
    let url = `${itemsUrls.recommendations(userIdentifier, itemId, categoryId, make, model, lat, lon, price)}`;

    if (state.api && state.api.queryParam && state.api.queryParam.enableMultiLang) {
        const urlParams = url.split('?')[1];
        const params = urlParams ? urlHelper.queryStringToParams(urlParams) : {};

        params.lang = state.api.queryParam.lang;

        url = url.split('?')[0];
        url = `${url}?${urlHelper.paramsToQueryString(params)}`;
    }

    if (shouldFetchByUrl(state, url)) {
        return Promise.all([
            dispatch(get(url, 'ITEMS'))
        ]);
    }

    return Promise.resolve();
};

/**
 * This method removes and ad
 */
const deleteItem = (userId, itemId, params) => dispatch =>
    new Promise((resolve, reject) => {
        if (!userId) {
            reject();
        }
        dispatch(del(itemsUrls.deleteItem(itemId), 'ITEMS', params)).then(res => {
            dispatch(customUsersActions.operateCounter(userId, 'published', 'SUBTRACT'));
            if (res && res.ok) {
                resolve(res.data);
            }
            reject(res);
        });
    });

const addVideos = (userId, itemId, params) => dispatch => {
    return new Promise((resolve, reject) => {
        if (!userId) {
            reject();
        }
        dispatch(post(itemsUrls.addVideo(itemId), 'ITEMS', params)).then(res => {
            if (res && res.ok) {
                resolve(res.data);
            }
            reject(res);
        });
    });
};

const editVideos = (userId, itemId, params) => dispatch => {
    return new Promise((resolve, reject) => {
        if (!userId) {
            reject();
        }
        dispatch(put(itemsUrls.editVideo(itemId), 'ITEMS', params)).then(res => {
            if (res && res.ok) {
                resolve(res.data);
            }
            reject(res);
        });
    });
};

export {
    getItems,
    loadNextPage,
    getItemsUrl,
    getItemsData,
    getItemsUser,
    getFavoriteItemsUser,
    loadNextPageItemsUser,
    loadNextPageFavoriteItemsUser,
    getSearchSuggestions,
    deleteItem,
    addVideos,
    editVideos,
    getRecommendations,
    sortRelevanceParams,
    trackSearch,
    getDealerSearchedItems
};
