import React from 'react';
import PropTypes from 'prop-types';

const DISPLAY = {
    BLOCK: 'block',
    FLEX: 'flex',
    INLINE_BLOCK: 'inline-block'
};

class OutsideClickListener extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        onOutsideClick: PropTypes.func,
        onEscape: PropTypes.func,
        display: PropTypes.oneOf(['block', 'flex', 'inline-block']),
        className: PropTypes.string
    };

    static defaultProps = {
        onOutsideClick: () => {},
        onEscape: () => {},
        display: DISPLAY.BLOCK
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('keydown', this.onKeyDown);
    }
    setLocationWrapperRef = node => {
        this.locationWrapperRef = node;
    }

    handleClickOutside = event => {
        if (this.locationWrapperRef && !this.locationWrapperRef.contains(event.target)) {
            this.props.onOutsideClick();
        }
    }

    onKeyDown = event => {
        if (event.key === 'Escape' && this.props.onEscape) {
            this.props.onEscape();
        }
    }

    render() {
        const { children, display, className } = this.props;
        const style = display !== DISPLAY.BLOCK && Object.values(DISPLAY).includes(display)
            ? { display } : undefined;

        return (
            <div ref={ this.setLocationWrapperRef } style={ style } className={ className }>
                { children }
            </div>
        );
    }
}
export default OutsideClickListener;
