import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import SearchAllCategories from './components/SearchAllCategories';
import BackToTop from './components/BackToTop';
import css from './SubHeader.desktop.scss';

const SubHeader = ({ showBackToTop, preNotificationAvailable }) => {
    return (
        <React.Fragment>
            <div className={ classnames(css.headerSearchContainer,
                preNotificationAvailable ? css.withPreNotification : css.withoutPreNotification
            ) }
            data-aut-id="subHeader"
            >
                <div className={ classnames(css.sectionWrapper, css.emptySection) }>
                    <SearchAllCategories data-aut-id="heroSearchCategory" />
                </div>

            </div>
            { !!showBackToTop && <BackToTop /> }
        </React.Fragment>
    );
};

SubHeader.propTypes = {
    showBackToTop: PropTypes.bool,
    preNotificationAvailable: PropTypes.bool
};

SubHeader.defaultProps = {
    showBackToTop: false
};

const mapStateToProps = state => ({
    preNotificationAvailable: state.preNotification.preNotificationAvailable
});

export default connect(mapStateToProps)(SubHeader);
